module.exports = [{
      plugin: require('/Users/jerry/Desktop/Programming/Website/personal_website/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/jerry/Desktop/Programming/Website/personal_website/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('/Users/jerry/Desktop/Programming/Website/personal_website/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-144484581-1","head":false,"anonymize":true,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"],"pageTransitionDelay":0},
    },{
      plugin: require('/Users/jerry/Desktop/Programming/Website/personal_website/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
